import React from 'react';
import { ScreenTypeEnum } from '../../enums'
import PortraitScreen from './portrait'
import { DeviceTypeEnum } from '../../enums'
class App extends React.Component{
  constructor(props){
    super(props);
    const screenMode = ScreenTypeEnum.PORTRAIT

    this.state = {
      screenMode,
    }

    this.setRem(screenMode)
  }

  componentDidMount(){
    setTimeout(() => {
      const screenMode = this.setUpScreenMode()
      this.setRem(screenMode)
    }, 100)
    
    window.addEventListener('resize', this.handleResize.bind(this))
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize.bind(this))
  }

  handleResize(){
    if(window.SCREEN_MODE === DeviceTypeEnum.PC){
      return
    }
    const screenMode = this.setUpScreenMode()
    this.setRem(screenMode)
  }


  setRem (screenMode){
    const docEl = document.documentElement
    let { width } = docEl.getBoundingClientRect()
    if(width > 440){
      width = 440
    }
    let rem = width / 4.14
    
    docEl.style.fontSize = `${rem.toFixed(1)}px`
  }

  setUpScreenMode(){
    const docEl = document.documentElement
    let { width,height } = docEl.getBoundingClientRect()
    let screenMode = ScreenTypeEnum.LANDSCAPE
    if(width >= height){
      screenMode = ScreenTypeEnum.LANDSCAPE
    }else{
      screenMode = ScreenTypeEnum.PORTRAIT
    }
    this.setState({
      screenMode: screenMode
    })
    return screenMode
  }

  render(){
    return <div className="App">
      {
       <PortraitScreen></PortraitScreen>
      }
    </div>
  }
}

export default App;
