import './portrait.css'
const goDwonLoad = (val) =>{
  window._gtBuryPoint?.send({
    'event_name': `${val}下载按钮点击`
  })
}
function PortraitScreen(){
  return <div className="screen screen--portrait">
            <header>
              <div className="logo">

              </div>
            </header>
            <section className="phone-bg">
            </section>
            <div className="download-btns">
                <a href="https://apps.apple.com/cn/app/%E7%B3%96%E8%B1%86-%E7%90%83%E7%90%83%E5%A4%A7%E4%BD%9C%E6%88%98%E5%AE%98%E6%96%B9%E7%A4%BE%E5%8C%BA/id6450919312" onClick={() => goDwonLoad('IOS')} className="btn btn--app-store">
                  {/* <div className='btn-beat-icon'>
                    beta
                  </div> */}
                </a>
                <a href="//file.campfires.cn/apk/tangdou_1.2.3.1008_guanwang_release.apk" onClick={() => goDwonLoad('安卓')} className="btn btn--google-play">
                  {/* <div className='btn-beat-icon'>
                    beta
                  </div> */}
                </a> 
              </div>
            <footer>
                <div className="brand">
                  ©2023 上海巨人网络科技有限公司 版权所有
                </div>
                <div className="links">
                <a href="https://beian.miit.gov.cn" className="link">沪B2-20050107-60</a> |
                <div className="link">沪网文(2020)4148-283</div> |
                <a href="/privacy_policy.html" className="link">用户协议</a> |
                <a href="/user_agreement.html" className="link">隐私政策</a>
                </div>
            </footer>
         </div>
}

export default PortraitScreen;